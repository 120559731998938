import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { useShow } from "@refinedev/core";
import { Edit, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IPartyResponse } from "../../../model/fabric/IPartyResponse";
import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { IExternalPartyResponse } from "../../../model/fabric/IExternalPartyResponse";
import { IServiceResponse } from "../../../model/IServiceResponse";


export const ExternalPartyEdit = () => {
  const {
    saveButtonProps,
    register,
    formState: { errors },
    control,
    setValue,
    refineCore: {
      queryResult
    }
  } = useForm<IExternalPartyResponse>();

  const record = queryResult?.data?.data;

  const { autocompleteProps } = useAutocomplete<IPartyResponse>({
    resource: "party",
    defaultValue: record?.party?.id
  });


  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={queryResult?.isLoading}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <FormControl>
          <Controller
            control={control}
            name="party"
            rules={{ required: "This field is required" }}
            defaultValue={null as any}
            render={({ field, fieldState }) => {
              return (
                <Autocomplete
                  id="party"
                  {...autocompleteProps}
                  {...field}
                  clearOnBlur={false}
                  onChange={(_, value) => {
                    field.onChange(value);
                  }}
                  getOptionLabel={(item) => item.code}
                  isOptionEqualToValue={(option, value) => value === undefined ||
                    option?.id?.toString() === (value?.id ?? value)?.toString()
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Party"
                      margin="normal"
                      variant="outlined"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      required
                    />
                  )}
                />
              );
            }}
          />
        </FormControl>




        <TextField
          {...register("code", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Code"}
          name="code"
        />

      </Box>
    </Edit>
  );
};
