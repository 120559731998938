import { useState, useRef, useCallback } from 'react';

type UseLongPressProps = {
  onLongPress: () => void;
  ms?: number;
};

const useLongPress = ({ onLongPress, ms = 1000 }: UseLongPressProps) => {
  const [isLongPress, setIsLongPress] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const start = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setIsLongPress(true);
      onLongPress();
    }, ms);
  }, [onLongPress, ms]);

  const clear = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setIsLongPress(false);
  }, []);

  return {
    onMouseDown: start,
    onMouseUp: clear,
    onMouseLeave: clear,
    onTouchStart: start,
    onTouchEnd: clear,
    onTouchMove: clear,
    isLongPress,
  };
};

export default useLongPress;
