import { BaseRecord, HttpError, useModal, useModalProps } from "@refinedev/core";
import { UseDataGridProps, useDataGrid } from "@refinedev/mui";

type Props<
    TQueryFnData extends BaseRecord = BaseRecord,
    TError extends HttpError = HttpError,
    TSearchVariables = unknown,
    TData extends BaseRecord = TQueryFnData,
> = UseDataGridProps<TQueryFnData, TError, TSearchVariables, TData> & useModalProps & {

};

export const useModalList = ({ defaultVisible, ...useDataGridProps }: Props) => {
    const listProps = useDataGrid(useDataGridProps);
    const modalProps = useModal({
        defaultVisible
    });

    return {
        listProps,
        modalProps
    }
}