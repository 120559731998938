import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    Edit,
    EditButton,
    List,
    Show,
    ShowButton,
    useAutocomplete,
    useDataGrid,
} from "@refinedev/mui";
import React from "react";
import { useCustom, useGetIdentity, useList, useShow, useTranslation } from "@refinedev/core";
import { IServiceResponse } from "../../../model/IServiceResponse";
import { GetServiceUrl } from "../../../helpers/configManager";
import { ISystemConfiguration } from "../../../model/definition/ISystemConfiguration";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useForm } from "@refinedev/react-hook-form";
import { IRoleResponse } from "../../../model/visa/role/IRoleResponse";
import { IUserResponse } from "../../../model/visa/user/IUserResponse";

export const SystemConfigurationSettings = () => {

    const { data, isLoading } = useCustom<IServiceResponse<ISystemConfiguration>>({
        url: `${GetServiceUrl()}/SystemConfiguration/GetCurrentOne`,
        method: 'get',
    });

    const { data: identity } = useGetIdentity<IUserResponse>();



    const {
        saveButtonProps,
        register,
        formState: { errors },
        control,
        setValue,
        refineCore: {
            queryResult
        }
    } = useForm<ISystemConfiguration>({
        refineCoreProps: {
            id: data?.data.data.id,
            resource: "SystemConfiguration",
            action: "edit"
        },
    });

    const { translate } = useTranslation();

    const { autocompleteProps } = useAutocomplete<IRoleResponse>({
        resource: "Role",
        defaultValue: data?.data.data.approvalRoleId
    });

    return (
        <Edit isLoading={isLoading} saveButtonProps={saveButtonProps}>
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off">
                <FormControl>
                    <Controller
                        control={control}
                        name="approvalRole"
                        defaultValue={null as any}
                        render={({ field, fieldState }) => {
                            return (
                                <Autocomplete
                                    id="approvalRole"
                                    {...autocompleteProps}
                                    {...field}
                                    clearOnBlur={false}
                                    onChange={(_, value) => {
                                        field.onChange(value);
                                    }}
                                    getOptionLabel={(item) => item.name}
                                    isOptionEqualToValue={(option, value) => value === undefined ||
                                        option?.id?.toString() === (value?.id ?? value)?.toString()
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="ApprovalRole"
                                            margin="normal"
                                            variant="outlined"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                </FormControl>

                <TextField
                    {...register("winsPortalConnectionString", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={"WINSPortalConnectionString"}
                    name="winsPortalConnectionString"
                    sx={{
                        display: identity?.isTenantAdmin ? 'block' : 'none'
                    }}
                />

                <TextField
                    {...register("erpConnectionString", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label={"ERPConnectionString"}
                    name="erpConnectionString"
                    sx={{
                        display: identity?.isTenantAdmin ? 'block' : 'none'
                    }}
                />
            </Box>
        </Edit>
    );
};
