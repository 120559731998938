import { Autocomplete, Box, TextField } from "@mui/material";
import { Create, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IExternalPartyResponse } from "../../../model/fabric/IExternalPartyResponse";
import { IPartyResponse } from "../../../model/fabric/IPartyResponse";
import { Controller } from "react-hook-form";
import { useTranslation } from "@refinedev/core";

export const RollCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
    control
  } = useForm({});


  const { autocompleteProps: partyAutocompleteProps } = useAutocomplete<IPartyResponse>({
    resource: "Party",
  });

  const { autocompleteProps: externalPartyAutocompleteProps } = useAutocomplete<IExternalPartyResponse>({
    resource: "ExternalParty",
    queryOptions: {
      enabled: control._formValues?.party?.id != null ?? false
    },
    filters: [
      {
        field: "PartyId",
        operator: "eq",
        value: control._formValues?.party?.id
      }
    ]
  });

  const { translate } = useTranslation();

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Controller
          control={control}
          name="party"
          rules={{
            validate: (value) => {
              if (value === undefined) {
                return translate("errors.required.field");
              }
              return true;
            },
          }}
          render={({ field }) => (
            <Autocomplete
              id="party"
              {...partyAutocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => item.code}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                option?.id?.toString() === (value?.id ?? value)?.toString()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Party"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="externalParty"
          rules={{
            validate: (value) => {
              if (value === undefined) {
                return translate("errors.required.field");
              }
              return true;
            },
          }}
          render={({ field }) => (
            <Autocomplete
              id="externalParty"
              {...externalPartyAutocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => item.code}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                option?.id?.toString() === (value?.id ?? value)?.toString()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="External Party"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />


        <TextField
          {...register("code", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Code"}
          name="code"
          required
        />

        <TextField
          {...register("rowOrder", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          inputProps={{ inputMode: 'numeric' }}
          label={"Row Order"}
          name="rowOrder"
          required
        />

        <TextField
          {...register("rollAmount", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          inputProps={{ inputMode: 'numeric' }}
          label={"Roll Amount"}
          name="rollAmount"
          required
        />

        <TextField
          {...register("rollAmountType", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Roll Amount Type"}
          name="rollAmountType"
          required
        />

        <TextField
          {...register("fabricType", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Fabric Type"}
          name="fabricType"
          required
        />

        <TextField
          {...register("rawMaterialCode", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Raw Material Code"}
          name="rawMaterialCode"
          required
        />

        <TextField
          {...register("rawMaterialName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Raw Material Name"}
          name="rawMaterialName"
          required
        />

        <TextField
          {...register("warehouse", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Warehouse"}
          name="warehouse"
          required
        />

        <TextField
          {...register("colorCode", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Color Code"}
          name="colorCode"
          required
        />

        <TextField
          {...register("colorName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Color Name"}
          name="colorName"
          required
        />

        <TextField
          {...register("dyehouse", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Dyehouse"}
          name="dyehouse"
          required
        />
      </Box>
    </Create>
  );
};
