import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useModalReturnType, useTranslation } from "@refinedev/core";
import { IScannerProps, Scanner, useDeviceList } from "../../../../components/scanner";

interface ReadBarcodeModelInterface {
    title: string;
}

type useReadBarcodeModalReturnType = useModalReturnType
    & ReadBarcodeModelInterface
    & IScannerProps;

export const ReadBarcodeModal = ({ show, close, visible, title, ...scannerProps }: useReadBarcodeModalReturnType) => {
    const { translate } = useTranslation();
    const devices = useDeviceList();

    return (
        <Dialog
            container={() => document.getElementById('fullscreen_div')}
            open={visible}
            onClose={close}
            PaperProps={{ sx: { minWidth: 500 } }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {visible ?
                    devices.length > 0 ?
                        <Scanner
                            {...scannerProps}
                        />
                        :
                        <Typography>
                            Sistem uygun bir cihaz bulamadı.
                            Kameraya erişim sağlanamıyor.
                            Lütfen kameraya izin verdiğinizden ve https üzerinden siteye bağlandığınızdan emin olunuz.
                        </Typography>
                    : <></>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{translate("buttons.cancel")}</Button>
            </DialogActions>
        </Dialog>
    );





}