import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EditButton,
  List,
  ShowButton,
  useDataGrid,
} from "@refinedev/mui";
import React from "react";
import { useTranslation } from "@refinedev/core";
import { Chip } from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { DeleteButton } from "../../../components/buttons/delete";

export const ErrorTypeGroupList = () => {
  const { dataGridProps } = useDataGrid({});

  const { translate } = useTranslation();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        flex: 1,
        headerName: translate("pages.definition.errorTypeGroup.fields.name"),
        minWidth: 200,
      },
      {
        field: "color",
        flex: 1,
        headerName: translate("pages.definition.errorTypeGroup.fields.color"),
        minWidth: 200,
        renderCell: ({ row }) => {
          return <Chip size="medium" color="default" style={{
            backgroundColor: row.color,
            width: "50%"
          }} />
        }
      },
      {
        field: "sortOrder",
        flex: 1,
        headerName: translate("pages.definition.errorTypeGroup.fields.sortOrder"),
        minWidth: 200,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
