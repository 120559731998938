import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Stepper,
    Step,
    StepLabel,
    Grid,
    TextField,
    Box,
    LinearProgress,
    Badge,
    Card,
    CardContent,
    CardHeader,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useModal, useList, HttpError, useApiUrl } from "@refinedev/core";
import { makeStyles } from '@mui/styles';
import { IErrorType } from '../../../../model/definition/IErrorType';
import { ErrorListModel } from '../show';
import useLongPress from '../../../../hooks/use-long-press';
import { useImageUpload, IFile } from '../../../../hooks/use-image-upload';
import { groupBy } from '../../../../helpers/arrayHelper';

type CreateRollErrorTypeModalProps = {
    close: () => void;
    show: () => void;
    visible: boolean;
    sellerCode: string;
    setSellerCode: React.Dispatch<React.SetStateAction<string>>;
    errorList: ErrorListModel[];
    setErrorList: React.Dispatch<React.SetStateAction<ErrorListModel[]>>;
    isUploadLoading: boolean;
    setIsUploadLoading: React.Dispatch<React.SetStateAction<boolean>>;
    processErrors: () => void;
    widthStart: number | null;
    setWidthStart: React.Dispatch<React.SetStateAction<number | null>>;
    widthMiddle: number | null;
    setWidthMiddle: React.Dispatch<React.SetStateAction<number | null>>;
    widthEnd: number | null;
    setWidthEnd: React.Dispatch<React.SetStateAction<number | null>>;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
};

const useStyles = makeStyles(() => ({
    button: {
        padding: 18,
        '@media (max-width: 600px)': { // Mobil cihazlar için
            width: 80,
            height: 80
        },
        '@media (min-width: 601px) and (max-width: 960px)': { // Tabletler için            
            width: 80,
            height: 80
        },
        '@media (min-width: 961px) and (max-width: 1280px)': { // Orta boyutlu ekranlar için
            width: 80,
            height: 80
        },
        '@media (min-width: 1281px)': { // Büyük ekranlar için
            width: 135,
            height: 135
        },
    },
    badge: {
        '& .MuiBadge-badge': {
            fontSize: 20,
            minWidth: 30,
            height: 30,
            borderRadius: 15
        }

    }
}));

const ConfirmedErrorRollInfoModal: React.FC<{
    createErrorRollInformationModalProps: ReturnType<typeof useModal>;
    activeStep: number;
    steps: { label: string; key: 'comment' | 'image' }[];
    handleNext: () => void;
    handleBack: () => void;
    handleErrorCommentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errorComment: string;
    onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isUploadLoading: boolean;
    images: IFile[];
    setErrors: () => void;
}> = ({
    createErrorRollInformationModalProps,
    activeStep,
    steps,
    handleNext,
    handleBack,
    handleErrorCommentChange,
    errorComment,
    onChangeHandler,
    isUploadLoading,
    images,
    setErrors
}) => (
        <Dialog
            open={createErrorRollInformationModalProps.visible}
            onClose={() => {
                createErrorRollInformationModalProps.close();
                handleErrorCommentChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
            }}
            PaperProps={{ sx: { width: '100%', height: '90%' } }}
            key="createErrorRollInformationModal"
            container={() => document.getElementById('fullscreen_div')}
        >
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Grid container columns={3} spacing={1}>
                    {steps[activeStep]?.key === 'comment' && (
                        <Grid item xs={3}>
                            <TextField
                                required
                                error={!errorComment}
                                value={errorComment}
                                onChange={handleErrorCommentChange}
                                label="Yorum"
                                margin="normal"
                                variant="outlined"
                                rows={2}
                                multiline
                                fullWidth
                            />
                        </Grid>
                    )}
                    {steps[activeStep]?.key === 'image' && (
                        <>
                            <Grid item xs={2}>
                                <label htmlFor="images-input">
                                    <input
                                        required
                                        id="images-input"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={onChangeHandler}
                                    />
                                    <LoadingButton
                                        loading={isUploadLoading}
                                        loadingPosition="end"
                                        endIcon={<FileUploadIcon />}
                                        variant="contained"
                                        component="span"
                                        style={{ marginTop: 16 }}
                                    >
                                        Upload
                                    </LoadingButton>
                                </label>
                            </Grid>
                            {images && images.length > 0 && (
                                <Grid item xs={1}>
                                    <Box
                                        component="img"
                                        sx={{ maxWidth: 250, maxHeight: 250 }}
                                        src={images[0].url}
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={createErrorRollInformationModalProps.close} variant='contained' color='error' fullWidth><Typography variant='h6'>Vazgeç</Typography></Button>
                {activeStep !== 0 && (
                    <Button onClick={handleBack} variant='contained' color='info' fullWidth><Typography variant='h6'>Geri Git</Typography></Button>
                )}
                {activeStep === steps.length - 1 ? (
                    <Button fullWidth onClick={() => {
                        if (steps[activeStep]?.key === 'comment') {
                            if (errorComment) {
                                setErrors();
                                createErrorRollInformationModalProps.close();
                            }
                            else return;
                        }
                        else if (steps[activeStep]?.key === 'image') {
                            if (images.length > 0) {
                                setErrors();
                                createErrorRollInformationModalProps.close();
                            }
                            else return;
                        }


                    }} variant='contained' color='success'><Typography variant='h6'>Devam</Typography></Button>
                ) : (
                    <Button variant="contained" onClick={() => {
                        if (steps[activeStep]?.key === 'comment') {
                            if (errorComment) handleNext();
                            else return;
                        }
                        else if (steps[activeStep]?.key === 'image') {
                            if (images.length > 0) handleNext();
                            else return;
                        }
                    }} color='success' fullWidth><Typography variant='h6'>Devam</Typography></Button>
                )}
            </DialogActions>
        </Dialog>
    );


const BeginInformationDialog: React.FC<{
    createErrorMainModalVisibility: boolean;
    sellerCodeModalProps: ReturnType<typeof useModal>;
    sellerCode: string;
    setSellerCode: React.Dispatch<React.SetStateAction<string>>;
    widthStart: number | null;
    setWidthStart: React.Dispatch<React.SetStateAction<number | null>>;
}> = ({ createErrorMainModalVisibility, sellerCodeModalProps, sellerCode, setSellerCode, widthStart, setWidthStart }) => (
    <Dialog open={createErrorMainModalVisibility && sellerCodeModalProps.visible}
        onClose={sellerCodeModalProps.close}
        container={() => document.getElementById('fullscreen_div')}
        PaperProps={{ sx: { width: '100%' } }}
        sx={{
            zIndex: 999999
        }}
        key={"SellerCodeDialogBegin"}
    >
        <DialogContent >
            <TextField
                required
                error={!sellerCode}
                value={sellerCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSellerCode(event.target.value);
                }}
                label="Satıcı Kodu"
                margin="normal"
                variant="outlined"
                inputProps={{
                    maxLength: 20,
                    inputMode: "numeric"
                }}

                type='number'
                fullWidth
                sx={{ width: '100%', height: '15%' }}
            />
            <TextField
                defaultValue={null}
                required
                error={!widthStart}
                value={widthStart}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = Number(event.target.value);

                    if (value > 0 && value <= 999) setWidthStart(value);
                    else if (value <= 0) setWidthStart(null);
                    else setWidthStart(999);
                }}
                label="Top Genişlik Başlangıç"
                margin="normal"
                variant="outlined"
                inputProps={{
                    inputMode: "numeric",
                    maxLength: 3,
                    max: 999
                }}
                type='number'

                fullWidth
                sx={{ width: '100%', height: '15%' }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={sellerCodeModalProps.close}>Vazgeç</Button>
            <Button onClick={sellerCodeModalProps.close}>Tamamla</Button>
        </DialogActions>
    </Dialog>

)


const EndInformationDialog: React.FC<{
    createErrorMainModalVisibility: boolean;
    sellerCodeModalProps: ReturnType<typeof useModal>;
    processErrors: () => void;
    setErrors: () => void;
    widthEnd: number | null;
    setWidthEnd: React.Dispatch<React.SetStateAction<number | null>>;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
}> = ({ createErrorMainModalVisibility, sellerCodeModalProps, setErrors, processErrors, widthEnd, setWidthEnd, comment, setComment }) => (
    <Dialog open={createErrorMainModalVisibility && sellerCodeModalProps.visible}
        onClose={sellerCodeModalProps.close}
        container={() => document.getElementById('fullscreen_div')}
        PaperProps={{ sx: { width: "100%" } }}
        sx={{
            zIndex: 999999
        }}
        key={"SellerCodeDialogEnd"}
    >
        <DialogContent>
            <TextField
                defaultValue={null}
                required
                error={!widthEnd}
                value={widthEnd}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = Number(event.target.value);

                    if (value > 0 && value <= 999) setWidthEnd(value);
                    else if (value <= 0) setWidthEnd(null);
                    else setWidthEnd(999);
                }}
                label="Top Genişlik Son"
                margin="normal"
                variant="outlined"
                inputProps={{
                    inputMode: "numeric",
                    maxLength: 3,
                    max: 999
                }}
                type='number'
                fullWidth
                sx={{ width: '100%', height: '15%' }}
            />
            <TextField
                defaultValue={null}
                required
                error={!comment}
                value={comment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setComment(event.target.value);
                }}
                label="Genel Yorum"
                margin="normal"
                variant="outlined"
                type='text'
                fullWidth
                multiline
                rows={4}
                sx={{ width: '100%', height: '15%' }}
            />
        </DialogContent>
        <DialogActions sx={{
            justifyContent: 'space-between',
        }}>
            <Button onClick={sellerCodeModalProps.close} variant='contained' color='error' fullWidth><Typography variant='h6'>Vazgeç</Typography></Button>
            <Button onClick={() => {
                setErrors();
                processErrors()
            }} variant='contained' color='success' fullWidth><Typography variant='h6'>Onaya Gönder</Typography></Button>
        </DialogActions>
    </Dialog >
)

const SetMiddleWidthDialog: React.FC<{
    rollMiddleWidthModalProps: ReturnType<typeof useModal>;
    widthMiddle: number | null;
    setWidthMiddle: React.Dispatch<React.SetStateAction<number | null>>;
}> = ({ rollMiddleWidthModalProps, widthMiddle, setWidthMiddle }) => (
    <Dialog open={rollMiddleWidthModalProps.visible}
        onClose={rollMiddleWidthModalProps.close}
        container={() => document.getElementById('fullscreen_div')}
        PaperProps={{ sx: { width: "100%" } }}
        sx={{
            zIndex: 999999
        }}
        key={"SellerCodeDialogEnd"}
    >
        <DialogContent>
            <TextField
                defaultValue={null}
                required
                error={!widthMiddle}
                value={widthMiddle}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const value = Number(event.target.value);

                    if (value > 0 && value <= 999) setWidthMiddle(value);
                    else if (value <= 0) setWidthMiddle(null);
                    else setWidthMiddle(999);
                }}
                label="Top Genişlik Orta"
                margin="normal"
                variant="outlined"
                inputProps={{
                    inputMode: "numeric",
                    maxLength: 3,
                    max: 999
                }}
                type='number'
                fullWidth
                sx={{ width: '100%', height: '15%' }}
            />
        </DialogContent>
        <DialogActions sx={{
            justifyContent: 'space-between',
        }}>
            <Button onClick={rollMiddleWidthModalProps.close} variant='contained' color='success' fullWidth><Typography variant='h6'>Tamamla</Typography></Button>
        </DialogActions>
    </Dialog >
)


export const CreateRollErrorTypeModal: React.FC<CreateRollErrorTypeModalProps> = ({
    close,
    show,
    visible,
    sellerCode,
    setSellerCode,
    errorList,
    setErrorList,
    isUploadLoading,
    setIsUploadLoading,
    processErrors,
    widthStart,
    widthEnd,
    widthMiddle,
    setWidthStart,
    setWidthMiddle,
    setWidthEnd,
    comment,
    setComment
}) => {
    const [errorComment, setErrorComment] = useState<string>('');
    const [errorButtonIsLoading, setErrorButtonIsLoading] = useState(false);
    const apiUrl = useApiUrl();
    const [images, setImages] = useState<IFile[]>([]);
    const [steps, setSteps] = useState<{ label: string; key: 'comment' | 'image' }[]>([]);
    const [activeStep, setActiveStep] = useState(0);
    const createErrorRollInformationModalProps = useModal();
    const beginInfoCodeModalProps = useModal();
    const endInfoCodeModalProps = useModal();
    const currentErrorTypeIdRef = useRef('');
    const currentErrorCodeRef = useRef('');
    const currentParentErrorTypeRef = useRef<IErrorType | undefined>();
    const rollMiddleWidthModalProps = useModal();

    console.log(errorList)

    const handleNext = useCallback(() => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }, []);

    const handleBack = useCallback(() => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }, []);

    const handleErrorCommentChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorComment(event.target.value);
    }, []);

    const onChangeHandler = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploadLoading(true);
        const target = event.target;
        const files: FileList = (target.files as FileList);
        const uploadedImages = await useImageUpload({ files, apiUrl });
        setImages(uploadedImages);
        setIsUploadLoading(false);
    }, [apiUrl, setIsUploadLoading]);

    const setErrors = useCallback(() => {
        if (!isUploadLoading) {
            setErrorList(prevList => [
                ...prevList,
                {
                    comment: errorComment,
                    errorTypeId: currentErrorTypeIdRef.current,
                    imageUrl: images.length > 0 ? images[0].url : '',
                    errorCode: currentErrorCodeRef.current,
                    parentErrorType: currentParentErrorTypeRef.current,
                },
            ]);
            setErrorComment('');
            setImages([]);
            incrementErrorCount(currentErrorTypeIdRef.current);
        }
    }, [errorComment, images, isUploadLoading, setErrorList]);

    const { data, isLoading } = useList<IErrorType, HttpError>({
        resource: 'ErrorType',
        pagination: { pageSize: 10000 }
    });

    const getBaseErrorId = (errorId: string) => data?.data.find((predicate: IErrorType) => predicate.childErrorTypes.find((childPredicate: IErrorType) => childPredicate.id === errorId))?.id ?? '';

    const [groupedData, setGroupedData] = useState<Record<string, IErrorType[]> | undefined>();
    const [errorCounts, setErrorCounts] = useState<Record<string, number>>({});
    const [errorBaseCounts, setErrorBaseCounts] = useState<Record<string, number>>({});
    const [baseErrorList, setBaseErrorList] = useState<string[]>([]);



    const incrementErrorCount = (errorId: string) => {
        setErrorCounts(prevCounts => ({
            ...prevCounts,
            [errorId]: (isNaN(prevCounts[errorId]) ? 0 : prevCounts[errorId]) + 1
        }));
        const baseErrorId = getBaseErrorId(errorId);
        if (baseErrorId) incrementBaseErrorCount(baseErrorId);
    };

    const decrementErrorCount = (errorId: string) => {
        setErrorCounts(prevCounts => ({
            ...prevCounts,
            [errorId]: prevCounts[errorId] > 0 ? prevCounts[errorId] - 1 : 0
        }));
        const baseErrorId = getBaseErrorId(errorId);
        if (baseErrorId) decrementBaseErrorCount(baseErrorId);
    };

    const incrementBaseErrorCount = (errorId: string) => {
        setErrorBaseCounts(prevCounts => ({
            ...prevCounts,
            [errorId]: (isNaN(prevCounts[errorId]) ? 0 : prevCounts[errorId]) + 1
        }));
    };

    const decrementBaseErrorCount = (errorId: string) => {
        setErrorBaseCounts(prevCounts => ({
            ...prevCounts,
            [errorId]: prevCounts[errorId] > 0 ? prevCounts[errorId] - 1 : 0
        }));
    };

    useEffect(() => {
        const groupedData = groupBy(data?.data, p => p.errorTypeGroupId);
        setGroupedData(groupedData);
        if (data?.data) {
            const baseErrors = data?.data.filter(errorType => errorType.childErrorTypes.length > 0).map(errorType => errorType.id);
            setBaseErrorList(baseErrors);
        }
    }, [data]);

    const longPressCurrentErrorRef = useRef<string>('');

    const onLongPress = () => {
        console.log("onLongPress basıldı")
        removeError(longPressCurrentErrorRef.current);
        longPressCurrentErrorRef.current = '';
    };

    const { onMouseDown, onMouseUp, onMouseLeave, onTouchStart, onTouchEnd, onTouchMove, isLongPress } = useLongPress({
        onLongPress,
        ms: 1000,
    });

    const [visibleSubError, setVisibleSubError] = useState(false);
    const [currentSubErrors, setCurrentSubErrors] = useState<IErrorType[]>([]);
    const [currentSubButtonColor, setCurrentSubButtonColor] = useState<string>('');

    const openSubButtonModal = useCallback((subErrors: IErrorType[], buttonColor: string) => {
        setCurrentSubErrors(subErrors);
        setCurrentSubButtonColor(buttonColor);
        setVisibleSubError(true);
    }, []);

    const closeSubError = useCallback(() => {
        setVisibleSubError(false);
    }, []);

    const removeError = (errorTypeId: string) => {
        const orgErrorList = errorList;
        var lastElementIndexToRemove = orgErrorList.findLastIndex(error => error.errorTypeId == errorTypeId)

        if (lastElementIndexToRemove !== -1) {
            decrementErrorCount(longPressCurrentErrorRef.current);
            orgErrorList.splice(lastElementIndexToRemove, 1);
        }

        setErrorList(orgErrorList);
    };

    const isTouchEventRef = useRef(false);

    const addError = (errorTypeId: string, errorCode: string, parentErrorType: IErrorType, isCommentMandatory: boolean, isProofMandatory: boolean) => {
        currentErrorCodeRef.current = errorCode;
        currentErrorTypeIdRef.current = errorTypeId;
        currentParentErrorTypeRef.current = parentErrorType;
        const newSteps: { label: string; key: "comment" | "image" }[] = [];
        if (isCommentMandatory) newSteps.push({ label: 'Yorum', key: 'comment' });
        if (isProofMandatory) newSteps.push({ label: 'Resim', key: 'image' });
        setSteps(newSteps);
        if (newSteps.length > 0) createErrorRollInformationModalProps.show();
        else setErrors();
    };

    const classes = useStyles();

    const drawButton = (record: IErrorType, buttonColor: string, label: string, subErrors?: IErrorType[]) => {
        if (subErrors) {
            return (
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} key={record.id}>
                    <Badge badgeContent={errorBaseCounts[record.id] ?? 0} color="primary" showZero className={classes.badge}>
                        <LoadingButton
                            className={classes.button}
                            style={{ backgroundColor: buttonColor }}
                            variant="contained"
                            onClick={() => openSubButtonModal(subErrors, buttonColor)}
                            loading={errorButtonIsLoading}>
                            <Typography fontWeight='bold' variant='h6'>
                                {label}
                            </Typography>
                        </LoadingButton>
                    </Badge>
                </Grid>
            );
        }
        return (
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} key={record.id}>
                <Badge badgeContent={errorCounts[record.id] ?? 0} color="primary" showZero className={classes.badge}>
                    <LoadingButton
                        className={classes.button}
                        onTouchEnd={(e) => {
                            e.preventDefault();
                            isTouchEventRef.current = true;
                            longPressCurrentErrorRef.current = record.id;
                            onTouchEnd();
                            if (!isLongPress) {
                                addError(record.id, record.code, record.parentErrorType, record.isCommentMandatory, record.isProofMandatory);
                            }
                        }}
                        onTouchStart={(e) => {
                            e.preventDefault();
                            isTouchEventRef.current = true;
                            longPressCurrentErrorRef.current = record.id;
                            onTouchStart()
                        }}
                        onMouseDown={() => {
                            if (!isTouchEventRef.current) {
                                longPressCurrentErrorRef.current = record.id;
                                onMouseDown()
                            }
                        }}
                        onMouseUp={() => {
                            if (!isTouchEventRef.current) {
                                longPressCurrentErrorRef.current = record.id;
                                onMouseUp();
                                if (!isLongPress) {
                                    addError(record.id, record.code, record.parentErrorType, record.isCommentMandatory, record.isProofMandatory);
                                }
                            }
                        }}
                        onTouchMove={onTouchMove}
                        onMouseLeave={onMouseLeave}
                        style={{ backgroundColor: buttonColor }}
                        variant="contained"
                        loading={errorButtonIsLoading}>
                        <Typography fontWeight='bold' variant='h6'>
                            {label}
                        </Typography>
                    </LoadingButton>
                </Badge>
            </Grid>
        );
    };

    const drawGroup = (categoryId: string) => {
        const title = groupedData![categoryId][0].errorTypeGroup.name;
        const buttonColor = groupedData![categoryId][0].errorTypeGroup.color;
        return (
            <Grid item key={categoryId} xs={8} sm={8} md={8} lg={8} xl={8}>
                <Card variant="outlined">
                    <CardHeader title={
                        <Typography variant="h3" color="primary" textAlign='center' fontWeight='bold' gutterBottom>
                            {title}
                        </Typography>
                    } />
                    <CardContent>
                        <Grid container spacing={3} columns={24} sx={{
                        }}>
                            {groupedData![categoryId].map(record => {
                                if (record.childErrorTypes && record.childErrorTypes.length > 0) {
                                    return drawButton(record, buttonColor, record.definition, record.childErrorTypes);
                                }
                                if (!record.parentErrorType) {
                                    return drawButton(record, buttonColor, record.definition);
                                }
                                return null;
                            })}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    const clearAllErrors = () => {
        console.log("Clear All Errors And Badges")
        setErrorList([]);
        setErrorCounts({})
        setErrorBaseCounts({})
    }

    useEffect(() => {
        beginInfoCodeModalProps.show();
    }, []);


    return (
        <>
            <Dialog
                open={visible}
                onClose={() => {
                    clearAllErrors();
                    close();
                }}
                PaperProps={{ sx: { minWidth: '99%', height: '90%' } }}
                container={() => document.getElementById('fullscreen_div')}
            >
                <DialogContent>
                    {isLoading && <LinearProgress variant="query" />}
                    <Grid container spacing={2} columns={24}>
                        {groupedData && Object.keys(groupedData).map(categoryId => drawGroup(categoryId))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setErrorList([]); close(); }}>Vazgeç</Button>
                    <Button onClick={endInfoCodeModalProps.show}>Tamamla</Button>
                    <Button onClick={rollMiddleWidthModalProps.show}>Top Orta Bilgisi Gir</Button>
                </DialogActions>
            </Dialog>
            <ConfirmedErrorRollInfoModal
                createErrorRollInformationModalProps={createErrorRollInformationModalProps}
                activeStep={activeStep}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
                handleErrorCommentChange={handleErrorCommentChange}
                errorComment={errorComment}
                onChangeHandler={onChangeHandler}
                isUploadLoading={isUploadLoading}
                images={images}
                setErrors={setErrors}
            />
            <EndInformationDialog
                processErrors={processErrors}
                setErrors={setErrors}
                setWidthEnd={setWidthEnd}
                widthEnd={widthEnd}
                sellerCodeModalProps={endInfoCodeModalProps}
                createErrorMainModalVisibility={visible}
                comment={comment}
                setComment={setComment}
            />
            <SetMiddleWidthDialog
                setWidthMiddle={setWidthMiddle}
                widthMiddle={widthMiddle}
                rollMiddleWidthModalProps={rollMiddleWidthModalProps}
            />
            <BeginInformationDialog
                setSellerCode={setSellerCode}
                sellerCode={sellerCode}
                setWidthStart={setWidthStart}
                widthStart={widthStart}
                sellerCodeModalProps={beginInfoCodeModalProps}
                createErrorMainModalVisibility={visible}
            />
            <Dialog open={visibleSubError} onClose={closeSubError} PaperProps={{ sx: { width: '100%', height: '90%' } }} key="subErrorDialog"
                container={() => document.getElementById('fullscreen_div')}>
                <DialogContent>
                    <Grid container spacing={2} columns={24}>
                        {currentSubErrors.map(record => drawButton(record, currentSubButtonColor, record.definition))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};
