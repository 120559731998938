import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EditButton,
  List,
  ShowButton,
  useDataGrid,
} from "@refinedev/mui";
import React from "react";
import { useTranslation } from "@refinedev/core";
import { Chip } from "@mui/material";
import { DeleteButton } from "../../../components/buttons/delete";

export const ErrorTypeList = () => {
  const { dataGridProps } = useDataGrid({});

  const { translate } = useTranslation();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "errorTypeGroup.name",
        flex: 1,
        headerName: translate("pages.definition.errorType.fields.categoryName"),
        minWidth: 200,
        renderCell: ({ row }) => (row.errorTypeGroup?.name)
      },
      {
        field: "parentErrorType",
        flex: 1,
        headerName: translate("pages.definition.errorType.fields.parentErrorType"),
        minWidth: 200,
        renderCell: ({ row }) => (row.parentErrorType ? (row.parentErrorType?.code + " - " + row.parentErrorType?.definition) : "")
      },
      {
        field: "code",
        flex: 1,
        headerName: translate("pages.definition.errorType.fields.code"),
        minWidth: 200,
      },
      {
        field: "definition",
        flex: 1,
        headerName: translate("pages.definition.errorType.fields.definition"),
        minWidth: 200,
      },
      {
        field: "point",
        flex: 1,
        headerName: translate("pages.definition.errorType.fields.point"),
        minWidth: 200,
      },
      {
        field: "reportRowOrder",
        flex: 1,
        headerName: translate("pages.definition.errorType.fields.reportRowOrder"),
        minWidth: 200,
      },
      {
        field: "isProofMandatory",
        flex: 1,
        headerName: translate("pages.definition.errorType.fields.isProofMandatory"),
        renderCell(params) {
          if (params.row.isProofMandatory) {
            return <Chip label={translate("booleanYesNo." + params.row.isProofMandatory.toString())} color="success" />;
          }

          return <Chip label={translate("booleanYesNo." + params.row.isProofMandatory.toString())} color="error" />;
        },
      },
      {
        field: "isCommentMandatory",
        flex: 1,
        headerName: translate("pages.definition.errorType.fields.isCommentMandatory"),
        renderCell(params) {
          if (params.row.isCommentMandatory) {
            return <Chip label={translate("booleanYesNo." + params.row.isProofMandatory.toString())} color="success" />;
          }

          return <Chip label={translate("booleanYesNo." + params.row.isProofMandatory.toString())} color="error" />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              {
                row?.childErrorTypes?.length ?? 0 > 0 ? <DeleteButton hideText recordItemId={row.id} confirmTitle={translate("buttons.confirmMultiple")} /> : <DeleteButton hideText recordItemId={row.id} />
              }
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
