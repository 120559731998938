import axios from "axios";
import { axiosInstance, getAccessToken } from "../../providers/data-provider/utils/axios";
import { useApiUrl } from "@refinedev/core";
import { GetCdnUrl } from "../../helpers/configManager";
import { IServiceResponse } from "../../model/IServiceResponse";

type Props = {
    files: FileList;
    apiUrl: string;
};

export interface IFile {
    name: string;
    size: number;
    type: string;
    lastModified: number;
    url: string;
}

export const useImageUpload = async ({ apiUrl, files }: Props) => {
    const formData = new FormData();

    const imagePaylod: IFile[] = [];

    Array.from(files).forEach((file: File) => {
        formData.append("file", file);
    });


    var accessToken = await getAccessToken();
    const res = await axiosInstance.post<IServiceResponse<{ url: string }>>(
        `${apiUrl}/media/upload`,
        formData,
        {
            withCredentials: false,
            headers: {
                "Authorization": "Bearer " + accessToken,
            },
        },
    );

    Array.from(files).forEach((file: File) => {
        const { name, size, type, lastModified } = file;

        imagePaylod.push({ name, size, type, lastModified, url: GetCdnUrl() + res.data?.data?.url });
    });


    return imagePaylod;
};