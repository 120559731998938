import { Autocomplete, Box, TextField } from "@mui/material";
import { Create, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IPartyResponse } from "../../../model/fabric/IPartyResponse";
import { Controller } from "react-hook-form";

export const ExternalPartyCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
    control
  } = useForm({});

  const { autocompleteProps: partyAutocompleteProps } = useAutocomplete<IPartyResponse>({
    resource: "party",
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >

        <Controller
          control={control}
          name="party"
          render={({ field }) => (
            <Autocomplete
              id="party"
              {...partyAutocompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => item.code}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                option?.id?.toString() === (value?.id ?? value)?.toString()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Party"
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />

        <TextField
          {...register("code", {
            required: true,
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Code"}
          name="code"
        />

      </Box>
    </Create>
  );
};
