import { DataGrid, GridCallbackDetails, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import {
  EditButton,
  ExportButton,
  List,
  ShowButton,
  useDataGrid,
} from "@refinedev/mui";
import React, { useState } from "react";
import { GetCdnUrl, GetServiceUrl } from "../../../helpers/configManager";
import { IServiceResponse } from "../../../model/IServiceResponse";
import { openUrlInNewTab } from "../../../helpers/generalHelper";
import { axiosInstance } from "../../../providers/data-provider/utils";
import { useCustomMutation, useNotification } from "@refinedev/core";
import { DeleteButton } from "../../../components/buttons/delete";

export const ExternalPartyList = () => {
  const { dataGridProps } = useDataGrid({});
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  var { mutate } = useCustomMutation();
  const { open } = useNotification();

  const exportExcel = async () => {

    if (selectedRows.length == 0) {
      alert("En az 1 adet satır seçmelisiniz!");
    }
    else {
      setExportLoading(true);
      mutate({
        url: `${GetServiceUrl()}/ExternalParty/getReports`,
        method: 'post',
        values: {
          Ids: selectedRows
        }
      }
        ,
        {
          onError: (err, variables, context) => {
            setExportLoading(false);
          },
          onSuccess: (data, variables, context) => {
            data?.data?.data.forEach((value: string) => {
              const reportUrl = GetCdnUrl() + value;
              openUrlInNewTab(reportUrl);
            });

            setExportLoading(false);
          },
        })
    }
  }

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "code",
        flex: 1,
        headerName: "Code",
        minWidth: 200,
      },
      {
        field: "party.code",
        flex: 1,
        headerName: "Party",
        minWidth: 200,
        valueGetter: (params: any) => params.row.party.code
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
              <DeleteButton hideText recordItemId={row.id} />

            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 200,
      },
    ],
    []
  );

  return (
    <List headerButtons={({ defaultButtons }) => (
      <>
        <ExportButton loading={exportLoading} variant="contained" onClick={() => exportExcel()} >DIŞA AKTAR</ExportButton>
        {defaultButtons}
      </>
    )}>
      <DataGrid {...dataGridProps} columns={columns} autoHeight checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
          setSelectedRows(rowSelectionModel);
        }} />
    </List>
  );
};
