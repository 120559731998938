import React, { useEffect, useImperativeHandle, useRef } from 'react';
import useStyles from './styles';
import { formatDate, formatDateWithTimeZone, formatTime } from '../../helpers/dateHelper';
import JsBarcode from 'jsbarcode';
import { QRCode } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

interface PrintTagProps {
    ref: React.Ref<HTMLDivElement>;
    createDate: Date;
    printDate: Date;
    externalPartyCode: string;
    partyCode: string;
    rawMaterialCode: string;
    fabricType: string;
    colorCode: string;
    rawMaterial: string;
    colorName: string;
    rollCode: string;
    dyeHouse: string;
    rollAmount: string;
    rollAmountType: string;
    id: string;
    approvementCount: number;
    errorEntryStartTime: Date | undefined;
    errorEntryEndTime: Date | undefined;
    isVerified: boolean | undefined
}

export interface PrintTagHandle {
    downloadPDF: () => void;
}

const PrintTag = React.forwardRef<PrintTagHandle, PrintTagProps>((props, ref) => {
    const classes = useStyles();
    const barcodeRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (barcodeRef.current) {
            JsBarcode(barcodeRef.current, props.rollCode.padStart(10, '0'), {
                format: "CODE128",
                displayValue: true,
                fontSize: 18,
            });
        }
    }, [props.rollCode]);


    const downloadPDF = async () => {
        const element = document.getElementById('print-content');
        const body = document.getElementsByTagName('body');

        if (element) {

            // Geçici olarak görünmez içeriği görünür yap
            element.style.display = 'block';

            const canvas = await html2canvas(element, {
                scale: 2, // Daha yüksek çözünürlük için ölçeklendirme
                useCORS: true, // Farklı kaynaklar arası istekleri desteklemek için
            });
            const data = canvas.toDataURL('image/png');

            element.style.display = 'none';

            // PDF oluştur ve indir
            const pdf = new jsPDF('p', 'mm', 'a4'); // 'pt' biriminde a4 boyutunda PDF oluştur
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // İçeriğin tamamını tek sayfada göstermek için boyutları hesapla
            const imgWidth = pdfWidth;
            const imgHeight = (canvas.height * pdfWidth) / canvas.width;

            // PDF sayfasının yüksekliğini içeriğe göre ayarla
            pdf.internal.pageSize.height = imgHeight;

            // İçeriği PDF'ye ekle
            pdf.addImage(data, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.autoPrint();
            window.open(pdf.output('bloburl'), '_blank');
        }
    };

    useImperativeHandle(ref, () => ({
        downloadPDF
    }));

    return (
        <div className={classes.root} id='print-content' style={{
            display: "none",
            position: "absolute",
            zIndex: -2
        }}>
            <div className={classes.label}>
                <div className={classes.header}>
                    <span>{formatDate(props.createDate)}</span>
                    <span>{formatDate(props.printDate)}</span>
                </div>
                <div className={classes.title}>
                    {props.externalPartyCode}
                </div>
                <div className={classes.subText}>
                    <div className={classes.subSubText}>{props.rawMaterialCode.trim()}</div>
                    <div className={classes.subSubText}>{props.fabricType.trim()}</div>
                    <div className={classes.subSubText}>{props.colorCode.trim()}</div>
                </div>
                <div className={classes.section} style={{
                    paddingTop: 15,
                }}>
                    <div className={classes.subSubText}>{props.rawMaterial.trim()}</div>
                    <div className={classes.subSubText}>{props.colorName.trim()}</div>
                </div>
                <div className={classes.section} style={{
                    display: 'block',
                    textAlign: 'start',
                    paddingTop: 15,
                }}>
                    <div>Top No: {props.rollCode.trim()}</div>
                    <div>Parti No: {props.partyCode.trim()}</div>
                    <div>Boyahane: {props.dyeHouse.trim()}</div>
                </div>
                <div className={classes.section} style={{
                    paddingTop: 15,
                }}>
                    HATA TESPİTİ SONRASI ÇIKTI
                </div>
                <div className={classes.barcode}>
                    <div>Miktar</div>
                    <div>{props.rollAmount} {props.rollAmountType.trim()}</div>
                </div>
                <div className={classes.barcodeImage}>
                    <svg ref={barcodeRef}></svg>
                </div>
                <div className={classes.barcodeImage}>
                    <QRCode value={"https://kumas.imteks.com.tr/roll/showexternal/" + (props.id?.toString() ?? "")} type="svg" style={{
                        width: ' 5cm',
                        height: '5cm'
                    }} />
                </div>
                <div className={classes.section} style={{
                    display: 'block',
                    textAlign: 'start',
                    paddingTop: 15,
                }}>
                    <div>K.S.: {props.approvementCount} Kontrol</div>
                    <div>B.T.: {formatDateWithTimeZone(props.errorEntryStartTime)}</div>
                    <div>T.T.: {formatDateWithTimeZone(props.errorEntryEndTime)} </div>
                    <div>O.D.: <strong>{props.isVerified ? "ONAYLI" : "ONAYLI DEĞİL"}</strong></div>
                </div>
            </div>
        </div>
    );
});

export default PrintTag;
