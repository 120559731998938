import { Box, TextField } from "@mui/material";
import { useShow } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";



export const PartyEdit = () => {
  const { queryResult } = useShow({});
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const {
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm(
    {
      defaultValues: {
        code: record?.code,
        definition: record?.definition,
        point: record?.point,
      },
      refineCoreProps: {
        queryOptions: {
          enabled: false
        }
      }
    }
  );

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("code", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Code"}
          name="code"
        />
        
      </Box>
    </Edit>
  );
};
