const groupBy = <T, K extends keyof any>(arr: T[] | undefined, key: (i: T) => K): Record<K, T[]> | undefined => {
    if (arr) {
        return arr.reduce((groups, item) => {
            (groups[key(item)] ||= []).push(item);
            return groups;
        }, {} as Record<K, T[]>);
    }

    return undefined;
}

type NumberOrObject = number | { [key: string]: any };

/**
 * Dizideki sayıların ve iç içe nesnelerdeki sayı değerlerinin toplamını hesaplar.
 * @param items Toplanacak sayıların veya nesnelerin dizisi
 * @param path Nesne içindeki sayısal değere erişmek için yol (anahtarlar dizisi)
 * @returns Dizi içindeki sayıların ve nesnelerdeki sayı değerlerinin toplamı
 */
const sum = (items: NumberOrObject[], path: string[]): number => {

    if (items == undefined) return 0;
    debugger
    return items.reduce<number>((acc, item) => {
        if (typeof item === 'number') {
            // Eğer item doğrudan bir sayı ise toplama dahil et
            return acc + item;
        }

        const value = getValueByPath(item, path);
        if (typeof value === 'number') {
            return acc + value;
        }

        return acc;
    }, 0);
}

/**
 * Belirli bir yola göre nesne içindeki değeri döndürür.
 * @param obj İçinden değer alınacak nesne
 * @param path Değere erişmek için yol (anahtarlar dizisi)
 * @returns Nesne içindeki yol ile belirlenen değer
 */
const getValueByPath = (obj: any, path: string[]): any => {
    return path.reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : undefined, obj);
}

export {
    groupBy,
    sum
};