import { Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "@refinedev/core";
import { Create, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { IRoleResponse } from "../../../model/visa/role/IRoleResponse";
import { Controller } from "react-hook-form";

export const UserCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
    control
  } = useForm({});

  const { translate } = useTranslation();

  const { autocompleteProps } = useAutocomplete<IRoleResponse>({
    resource: "Role",
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("firstName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.firstName")}
          name="firstName"
        />
        <TextField
          {...register("lastName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.lastName")}
          name="lastName"
        />
        <TextField
          {...register("email", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.email")}
          name="email"
        />
        <TextField
          {...register("userName", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.username")}
          name="userName"
        />
        <TextField
          {...register("password", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.password")}
          name="password"
        />
        <TextField
          {...register("phoneNumber")}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("pages.visa.user.fields.phoneNumber")}
          name="phoneNumber"
        />

        <Controller
          control={control}
          name="roles"
          rules={{ required: "This field is required" }}
          render={({ field }) => {
            const newValue = autocompleteProps.options.filter(
              (p) => field.value?.find((v: any) => v?.id === p?.id) !== undefined,
            );

            return (
              <Autocomplete
                id="roles"
                {...autocompleteProps}
                {...field}
                value={newValue}
                multiple
                clearOnBlur={false}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(item) => item.name}
                isOptionEqualToValue={(option, value) =>
                  value === undefined ||
                  option?.id?.toString() === (value?.id ?? value)?.toString()
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("pages.visa.user.fields.roles")}
                    margin="normal"
                    variant="outlined"
                    required
                  />
                )}
              />
            )
          }}
        />
      </Box>
    </Create>
  );
};
