import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EditButton,
  List,
  ShowButton,
  useDataGrid,
} from "@refinedev/mui";
import React, { useState } from "react";
import { useCustomMutation, useInvalidate, useNotification, useTranslation } from "@refinedev/core";
import { Button, Chip } from "@mui/material";
import { GetServiceUrl } from "../../../helpers/configManager";
import { LoadingButton } from "@mui/lab";
import { DeleteButton } from "../../../components/buttons/delete";

export const UserList = () => {
  const { dataGridProps } = useDataGrid({});

  const { translate } = useTranslation();
  const [syncButtonLoading, setSyncButtonLoading] = useState(false);
  const { open, close } = useNotification();
  const invalidate = useInvalidate();
  var { mutate: syncUsersMutate } = useCustomMutation();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "firstName",
        flex: 1,
        headerName: translate("pages.visa.user.fields.firstName"),
      },
      {
        field: "lastName",
        flex: 1,
        headerName: translate("pages.visa.user.fields.lastName"),
      },
      {
        field: "email",
        flex: 1,
        headerName: translate("pages.visa.user.fields.email"),
      },
      {
        field: "userName",
        flex: 1,
        headerName: translate("pages.visa.user.fields.username"),
      },
      {
        field: "phoneNumber",
        flex: 1,
        headerName: translate("pages.visa.user.fields.phoneNumber"),
      },
      {
        field: "isActive",
        flex: 1,
        headerName: translate("pages.visa.user.fields.isActive"),
        renderCell(params) {
          if (params.row.isActive) {
            return <Chip label={translate("booleanYesNo.true")} color="success" />;
          }

          return <Chip label={translate("booleanYesNo.false")} color="error" />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              <EditButton hideText recordItemId={row.id} />
              <DeleteButton hideText recordItemId={row.id} />
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    []
  );



  const syncUsers = () => {
    setSyncButtonLoading(true);
    syncUsersMutate({
      url: `${GetServiceUrl()}/User/syncUsers`,
      method: 'post',
      values: {}
    }
      ,
      {
        onError: (error, variables, context) => {
          setSyncButtonLoading(false);
        },
        onSuccess: (data, variables, context) => {
          open?.({
            type: 'success',
            key: 'syncUsers',
            message: translate("notifications.success")
          });
          setSyncButtonLoading(false);

          invalidate({
            resource: "User",
            invalidates: ["list"],
          });
        },
      })
  }

  return (
    <List headerButtons={({ defaultButtons }) => (
      <>
        {defaultButtons}
        <LoadingButton loading={syncButtonLoading} variant="contained" onClick={() => syncUsers()}>{translate("pages.visa.user.buttons.syncUsers")}</LoadingButton>
      </>
    )}>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
