export const CustomPalettes = {
  Green: {
    mode: "light",
    primary: {
      main: "#388E3C",  // Ana yeşil
      light: "#66BB6A",  // Açık yeşil
      dark: "#2E7D32",   // Koyu yeşil
    },
    secondary: {
      main: "#8BC34A",  // İkincil yeşil
      light: "#AED581",  // Açık ikincil yeşil
      dark: "#689F38",   // Koyu ikincil yeşil
    },
    error: {
      main: "#D32F2F",  // Hata rengi
      light: "#E57373",
      dark: "#C62828",
    },
    background: {
      default: "#F5F5F5",  // Arka plan rengi
      paper: "#FFFFFF",
    },
    text: {
      primary: "#212121",  // Ana metin rengi
      secondary: "#757575",
    },
  },
  GreenDark: {
    mode: "dark",
    primary: {
      main: "#81C784",  // Ana yeşil
      light: "#A5D6A7",  // Açık yeşil
      dark: "#388E3C",   // Koyu yeşil
    },
    secondary: {
      main: "#66BB6A",  // İkincil yeşil
      light: "#98EE99",  // Açık ikincil yeşil
      dark: "#338a3e",   // Koyu ikincil yeşil
    },
    error: {
      main: "#EF5350",  // Hata rengi
      light: "#EF9A9A",
      dark: "#D32F2F",
    },
    text: {
      primary: "#FFFFFF",  // Ana metin rengi
      secondary: "#BDBDBD",
    },
  }
} as const;
