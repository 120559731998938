import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

type ValueCardProps = {
    title: string,
    children?: React.ReactNode;
    secondaryChildren?: React.ReactNode;
    href?: string;
}

export const ValueCard = ({ title, children, secondaryChildren, href }: ValueCardProps) => (
    <React.Fragment>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {title}
        </Typography>
        <Typography component="p" variant="h4">
            {children}
        </Typography>
        <Typography color="text.secondary" sx={{ flex: 1 }}>
            {secondaryChildren}
        </Typography>
        {href != undefined ? <Link color="primary" to={href}>
            Detay
        </Link> : <></>}

    </React.Fragment>
)