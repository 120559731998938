import { Box, FormControl, FormLabel, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useShow, useTranslation } from "@refinedev/core";
import { Edit } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { MuiColorInput } from "mui-color-input";
import { Controller } from "react-hook-form";



export const ErrorTypeGroupEdit = () => {
  const { queryResult } = useShow({});
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { translate } = useTranslation();

  const {
    saveButtonProps,
    register,
    formState: { errors },
    control,
    setValue
  } = useForm(
    {
      defaultValues: {
        name: record?.name,
        color: record?.color,
        sortOrder: record?.sortOrder
      },
      refineCoreProps: {
        queryOptions: {
          enabled: false
        }
      }
    }
  );

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("name", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Category Name"}
          name="name"
        />
        <TextField
          {...register("sortOrder", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          inputProps={{ inputMode: 'numeric' }}
          label={"Sıralama"}
          name="sortOrder"
        />

        <FormControl>
          <FormLabel>{translate("pages.definition.errorTypeGroup.fields.color")}</FormLabel>
          <Controller
            control={control}
            name="color"
            rules={{
              validate: (value) => {
                if (value === undefined) {
                  return translate("errors.required.field");
                }
                return true;
              },
            }}
            defaultValue="#ffffff"
            render={({ field }) => (
              <MuiColorInput format="hex" value={field.value} onChange={(value: string) => {
                setValue("color", value, {
                  shouldValidate: true,
                });
              }} />
            )}
          />
        </FormControl>
      </Box>
    </Edit>
  );
};
