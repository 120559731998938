import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { RefineThemes } from "@refinedev/mui";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: RefineThemes.Yellow.palette.background.paper,
    color: RefineThemes.Yellow.palette.text.primary,
  },
  label: {
    border: '1px solid black',
    padding: theme.spacing(2),
    width: '300px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subText: {
    fontSize: '12px',
    display: 'flex'
  },
  subSubText: {
    flex: '1'
  },
  section: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    borderTop: '1px solid black',
    textAlign: 'center',
  },
  barcode: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    borderTop: '1px solid black',
    paddingTop: theme.spacing(1),
  },
  barcodeImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    borderTop: '1px solid black',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  barcodeText: {
    textAlign: 'center',
    width: '100%',
  },
}));

export default useStyles;
