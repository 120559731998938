import { Stack, Typography } from "@mui/material"
import { TextFieldComponent } from "@refinedev/mui"

import React from "react"

export const ValueField = ({ label, value, bgColor = "", textColor = "", center = false, singleLine = false, fullWidth = false }: { label: React.ReactNode, value: React.ReactNode, center?: boolean, singleLine?: boolean, bgColor?: string, textColor?: string, fullWidth?: boolean }) => {
    if (bgColor != "" && textColor != "") {
        if (singleLine) {
            return (
                <Stack gap={1} justifyContent={center ? "center" : "unset"} alignItems={center ? "center" : "unset"} direction="row">
                    <Typography variant="body1" fontWeight="bold" textAlign={center ? "center" : "unset"}>
                        {label}
                    </Typography>
                    <TextFieldComponent textAlign={center ? "center" : "unset"} value={value} sx={{
                        backgroundColor: bgColor,
                        color: textColor,
                        width: fullWidth ? '100%' : 'auto',
                    }} />
                </Stack>
            )
        }

        return (
            <Stack gap={1} justifyContent={center ? "center" : "unset"} alignItems={center ? "center" : "unset"}>
                <Typography variant="body1" fontWeight="bold" textAlign={center ? "center" : "unset"}>
                    {label}
                </Typography>
                <TextFieldComponent textAlign={center ? "center" : "unset"} value={value} sx={{
                    backgroundColor: bgColor,
                    color: textColor,
                    width: fullWidth ? '100%' : 'auto',
                }} />
            </Stack>
        )
    }


    else if (bgColor != "") {
        if (singleLine) {
            return (
                <Stack gap={1} direction="row" justifyContent={center ? "center" : "unset"} alignItems={center ? "center" : "unset"}>
                    <Typography variant="body1" fontWeight="bold" textAlign={center ? "center" : "unset"} >
                        {label}
                    </Typography>
                    <TextFieldComponent textAlign={center ? "center" : "unset"} value={value} sx={{
                        backgroundColor: bgColor,
                        width: fullWidth ? '100%' : 'auto',
                    }} />
                </Stack>
            )
        }

        return (
            <Stack gap={1} justifyContent={center ? "center" : "unset"} alignItems={center ? "center" : "unset"}>
                <Typography variant="body1" fontWeight="bold" textAlign={center ? "center" : "unset"} >
                    {label}
                </Typography>
                <TextFieldComponent width="100%" textAlign={center ? "center" : "unset"} value={value} sx={{
                    backgroundColor: bgColor,
                    width: fullWidth ? '100%' : 'auto',
                }} />
            </Stack>
        )
    }


    else if (textColor != "") {

        if (singleLine) {
            return (
                <Stack gap={1} justifyContent={center ? "center" : "unset"} alignItems={center ? "center" : "unset"} direction="row">
                    <Typography variant="body1" fontWeight="bold" textAlign={center ? "center" : "unset"}>
                        {label}
                    </Typography>
                    <TextFieldComponent textAlign={center ? "center" : "unset"} value={value} sx={{
                        color: textColor,
                        width: fullWidth ? '100%' : 'auto',
                    }} />
                </Stack>
            )
        }

        return (
            <Stack gap={1} justifyContent={center ? "center" : "unset"} alignItems={center ? "center" : "unset"}>
                <Typography variant="body1" fontWeight="bold" textAlign={center ? "center" : "unset"}>
                    {label}
                </Typography>
                <TextFieldComponent textAlign={center ? "center" : "unset"} value={value} sx={{
                    color: textColor,
                    width: fullWidth ? '100%' : 'auto',
                }} />
            </Stack>
        )
    }
    else {
        if (singleLine) {
            return (
                <Stack gap={1} justifyContent={center ? "center" : "unset"} alignItems={center ? "center" : "unset"} direction="row">
                    <Typography variant="body1" fontWeight="bold" textAlign={center ? "center" : "unset"}>
                        {label}
                    </Typography>
                    <TextFieldComponent textAlign={center ? "center" : "unset"} value={value} sx={{
                        width: fullWidth ? '100%' : 'auto',
                    }} />
                </Stack>
            )
        }

        return (
            <Stack gap={1} justifyContent={center ? "center" : "unset"} alignItems={center ? "center" : "unset"}>
                <Typography variant="body1" fontWeight="bold" textAlign={center ? "center" : "unset"}>
                    {label}
                </Typography>
                <TextFieldComponent textAlign={center ? "center" : "unset"} value={value} sx={{
                    width: fullWidth ? '100%' : 'auto',
                }} />
            </Stack>
        )
    }
}