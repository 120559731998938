function openUrlInNewTab(url: string): void {
  // Yeni sekmede URL aç
  const newWindow = window.open(url, '_blank');

  // Yeni pencereye odaklan
  if (newWindow) {
    newWindow.focus();
  }
}

export {
  openUrlInNewTab
};

