const getDateDiffInMinAndSeconds = (startDate: Date | string, endDate: Date | string): string => {

    let _startDate: Date;
    let _endDate: Date;

    // startDate string ise Date nesnesine çevir
    if (typeof startDate === 'string') {
        _startDate = new Date(startDate);
    } else {
        _startDate = startDate;
    }

    // endDate string ise Date nesnesine çevir
    if (typeof endDate === 'string') {
        _endDate = new Date(endDate);
    } else {
        _endDate = endDate;
    }



    const differenceInMilliseconds = _endDate.getTime() - _startDate.getTime();
    const differenceInSeconds = Math.round(differenceInMilliseconds / 1000);


    const minutes = Math.round(differenceInSeconds / 60);
    const seconds = differenceInSeconds % 60;

    return `${minutes} dakika ve ${seconds} saniye`
}

const formatDate = (date?: Date): string => {
    if (date) {
        const day = String(new Date(date).getDate()).padStart(2, '0');
        const month = String(new Date(date).getMonth() + 1).padStart(2, '0'); // getMonth() 0-11 arası değer döner, bu yüzden 1 ekliyoruz
        const year = new Date(date).getFullYear();

        return `${day}/${month}/${year}`;
    }
    else {
        return `01/01/2000`;
    }
}

const formatTime = (date?: Date): string => {
    if (date) {
        const hour = String(new Date(date).getHours()).padStart(2, '0');
        const minute = String(new Date(date).getMinutes()).padStart(2, '0'); // getMonth() 0-11 arası değer döner, bu yüzden 1 ekliyoruz
        const seconds = new Date(date).getSeconds();

        return `${hour}:${minute}:${seconds}`;
    }
    else {
        return `00:00:00`;
    }
}

const formatDateWithTimeZone = (dateString?: string | Date): string => {
    if (dateString) {
        try {
            const date = new Date(dateString);

            // Tarayıcının saat dilimi farkını dakika cinsinden alın
            const browserTimeZoneOffset = date.getTimezoneOffset();

            // Tarih bilgisindeki saat dilimi farkını dakika cinsinden alın
            const targetTimeZoneOffset = -date.getTimezoneOffset() - (date.getTimezoneOffset() - browserTimeZoneOffset);

            // Saat dilimi farkını milisaniye cinsinden hesaplayın
            const offsetTime = date.getTime() + targetTimeZoneOffset * 60 * 1000;

            // Yeni tarih nesnesi oluşturun
            const newDate = new Date(offsetTime);

            // Tarihi ve saati yerel formatta döndürün
            return newDate.toLocaleString();
        }
        catch {
            return "";
        }
    }
    else {
        return "";
    }

}

export {
    getDateDiffInMinAndSeconds,
    formatDate,
    formatTime,
    formatDateWithTimeZone
};