import { Button, Chip, Stack, TextField, Typography } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useCustomMutation, useInvalidate, useModal, useNavigation, useNotification, useTranslation } from "@refinedev/core";
import {
  EditButton,
  List,
  ShowButton,
  useDataGrid,
} from "@refinedev/mui";
import React, { useEffect, useRef, useState } from "react";
import { ReadBarcodeModal } from "./modals/readBarcodeModal";
import { BarcodeFormat, Result } from "@zxing/library";
import { GetServiceUrl } from "../../../helpers/configManager";
import { LoadingButton } from "@mui/lab";
import { getLastPartOfUrl } from "../../../helpers/stringHelper";
import { useDebounce } from 'use-debounce';
import { DeleteButton } from "../../../components/buttons/delete";


export const RollList = () => {
  const invalidate = useInvalidate();
  const [searchText, setSearchText] = useState('');
  const [debounceTime, setDebounceTime] = useState(3000); // Başlangıç debounce süresi
  const [filterValue] = useDebounce(searchText, debounceTime);
  const [remainingTime, setRemainingTime] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (searchText !== filterValue) {
      clearTimeout(timerRef.current);
      setRemainingTime((debounceTime / 1000) - 1);

      const updateRemainingTime = () => {
        setRemainingTime(prevTime => {
          if (prevTime <= 0) {
            clearTimeout(timerRef.current);
            return 0;
          }
          return prevTime - 1;
        });
      };

      timerRef.current = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timerRef.current);
    }
  }, [searchText, filterValue, debounceTime]);

  const { dataGridProps } = useDataGrid({
    filters: {
      permanent: [
        {
          operator: "or",
          value: [
            {
              field: "Code",
              operator: "contains",
              value: filterValue
            },
            {
              field: "SellerCode",
              operator: "contains",
              value: filterValue
            },
            {
              field: "ExternalParty.Code",
              operator: "contains",
              value: filterValue
            },
            {
              field: "ExternalParty.Party.Code",
              operator: "contains",
              value: filterValue
            }
          ]
        }
      ]
    }
  });

  const { translate } = useTranslation();

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "code",
        headerName: translate("pages.fabric.roll.fields.code"),
        minWidth: 50,
      },
      {
        field: "externalParty.party.code",

        headerName: translate("pages.fabric.roll.fields.party"),
        minWidth: 50,
        valueGetter: (params: any) => params.row.externalParty.party.code
      },
      {
        field: "externalParty.code",

        headerName: translate("pages.fabric.roll.fields.externalParty"),
        minWidth: 50,
        valueGetter: (params: any) => params.row.externalParty.code
      },
      {
        field: "fabricType",
        minWidth: 100,
        headerName: translate("pages.fabric.roll.fields.fabricType"),
      },
      {
        field: "rawMaterialName",
        flex: 1,
        headerName: translate("pages.fabric.roll.fields.rawMaterialName"),
      },
      {
        field: "warehouse",
        minWidth: 100,
        headerName: translate("pages.fabric.roll.fields.warehouse"),
      },
      {
        field: "colorName",
        flex: 1,
        headerName: translate("pages.fabric.roll.fields.colorName"),
      },
      {
        field: "dyehouse",
        flex: 1,
        headerName: translate("pages.fabric.roll.fields.dyehouse"),
      },
      {
        field: "isVerified",
        minWidth: 100,
        headerName: translate("pages.visa.user.fields.isVerified"),
        renderCell(params) {
          if (params.row.isVerified) {
            return <Chip label={translate("booleanYesNo.true")} color="success" />;
          }

          return <Chip label={translate("booleanYesNo.false")} color="error" />;
        },
      },
      {
        field: "isInApproval",
        minWidth: 100,
        headerName: translate("pages.visa.user.fields.isInApproval"),
        renderCell(params) {
          if (params.row.isInApproval) {
            return <Chip label={translate("booleanYesNo.true")} color="success" />;
          }

          return <Chip label={translate("booleanYesNo.false")} color="error" />;
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <>
              {/*<EditButton hideText recordItemId={row.id} />*/}
              <ShowButton recordItemId={row.id} >Göster</ShowButton>
              <DeleteButton recordItemId={row.id}>Sil</DeleteButton>
            </>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 160,
      },
    ],
    []
  );

  const { show, close, visible } = useModal();
  const { open } = useNotification();
  const { show: navigateShow } = useNavigation();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  var { mutate: sendApprovalsMutation } = useCustomMutation();
  const [approveLoading, setApproveLoading] = useState(false);
  var { mutate } = useCustomMutation();

  const onResult = (text: string, result: Result) => {
    //Barkod okunduktan sonra yapılacak işlemler. E128 ve QR için akış olmalı.

    if (result.getBarcodeFormat() == BarcodeFormat.QR_CODE) {
      close();
      navigateShow('Roll', removeBasePathFromUrl(text));
    }
    else {
      console.log("Barkod Tipi: " + result.getBarcodeFormat().toString());

      mutate({
        url: `${GetServiceUrl()}/Roll/getRollIdFromRollCode`,
        method: 'post',
        values: {
          code: getLastPartOfUrl(text)
        }
      }
        ,
        {
          onError: (error, variables, context) => {
            close();
            alert("İlgili barkoda ait top bulunamadı. Okunan değer: " + text)
          },
          onSuccess: (data, variables, context) => {
            navigateShow('Roll', data.data.data!);
            close();
          },
        })
    }
  }

  const onError = (err: Error) => {
    open?.({
      message: err.message,
      type: "error",
      description: err.name,
      key: 'error-barcode-reader'
    });
    close();
  }

  const onSendApprovals = () => {
    if (!selectedRows || selectedRows.length == 0) {
      alert("Hiç satır seçilmedi!");
      setApproveLoading(false);
    }
    else {
      sendApprovalsMutation(
        {
          url: `${GetServiceUrl()}/Roll/sendapprovelist`,
          method: 'post',
          values: {
            Id: selectedRows
          },
        }
        ,
        {
          onError: (error, variables, context) => {
            open?.({
              type: 'error',
              key: 'send-approvals',
              message: translate("notifications.error", { statusCode: error.statusCode }),
              description: error.message
            });
            setApproveLoading(false);
          },
          onSuccess: (data, variables, context) => {
            open?.({
              type: 'success',
              key: 'send-approvals',
              message: translate("notifications.success")
            });
            setApproveLoading(false);

            invalidate({
              resource: "Roll",
              invalidates: ["list"],
            });
          },
        }
      );
    }

  }

  return (
    <List title={
      <Stack direction="row" spacing="2">
        <Typography variant="h4">
          {translate("pages.fabric.roll.label")}
        </Typography>
        <TextField
          value={searchText}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
          }}
          id="searchText"
          margin="normal"
          label={"Filtre (" + remainingTime + " saniye)"}
          name="searchText"
          type="text"
          autoComplete="Kumaş Kodu, Harici Parti Kodu, Satıcı Kodu,Parti Kodu"
          placeholder="Kumaş Kodu, Harici Parti Kodu, Satıcı Kodu,Parti Kodu"
          size="small"
          style={{
            width: "50%",
            marginLeft: 10
          }}
        />
      </Stack>
    } headerButtons={({ defaultButtons }) => (
      <>

        <Button onClick={() => show()} variant="contained">{translate("buttons.readBarcode")}</Button>
        <LoadingButton variant="contained" loading={approveLoading} onClick={() => { setApproveLoading(true); onSendApprovals() }} color="info">{translate("pages.fabric.roll.buttons.sendApprove")}</LoadingButton>
        {defaultButtons}
      </>

    )}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        checkboxSelection
        disableRowSelectionOnClick
        onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
          setSelectedRows(rowSelectionModel);
        }}
      />

      <ReadBarcodeModal
        show={show}
        close={close}
        visible={visible}
        title="Barcode"
        onResult={onResult}
        onError={onError}
        oneTimeScan={true}
        options={{
          constraints: {
            facingMode: 'environment'
          }
        }}
      />
    </List>
  );
};

const removeBasePathFromUrl = (text: string) => text.replace("https://kumas.imteks.com.tr/roll/showexternal/", "");

