import { Autocomplete, Box, FormControl, FormLabel, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useTranslation } from "@refinedev/core";
import { Create, useAutocomplete } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import { Controller } from "react-hook-form";
import { IErrorTypeGroup } from "../../../model/definition/IErrorTypeGroup";
import { IErrorType } from "../../../model/definition/IErrorType";

export const ErrorTypeCreate = () => {
  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
    control,
    setValue
  } = useForm({});


  const { translate } = useTranslation();

  const { autocompleteProps: errorTypeGroupAutoCompleteProps } = useAutocomplete<IErrorTypeGroup>({
    resource: "ErrorTypeGroup",
  });

  const { autocompleteProps: errorTypeAutoCompleteProps } = useAutocomplete<IErrorType>({
    resource: "ErrorType",
  });


  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Controller
          control={control}
          name="errorTypeGroup"
          render={({ field }) => (
            <Autocomplete
              id="errorTypeGroup"
              {...errorTypeGroupAutoCompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => item.name}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                option?.id?.toString() === (value?.id ?? value)?.toString()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate("pages.definition.errorType.fields.errorTypeGroup")}
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="parentErrorType"
          render={({ field }) => (
            <Autocomplete
              id="parentErrorType"
              {...errorTypeAutoCompleteProps}
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => item.code + " - " + item.definition}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                option?.id?.toString() === (value?.id ?? value)?.toString()
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translate("pages.definition.errorType.fields.parentErrorType")}
                  margin="normal"
                  variant="outlined"
                />
              )}
            />
          )}
        />

        <TextField
          {...register("code", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Code"}
          name="code"
        />
        <TextField
          {...register("definition", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={"Definition"}
          name="definition"
        />
        <TextField
          {...register("point", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          inputProps={{ inputMode: 'numeric' }}
          label={"Point"}
          name="point"
        />

        <TextField
          {...register("reportRowOrder", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.title}
          helperText={(errors as any)?.title?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          inputProps={{ inputMode: 'numeric' }}
          label={"Report Row Order"}
          name="reportRowOrder"
        />

        <FormControl>
          <FormLabel>{translate("pages.definition.errorType.fields.isProofMandatory")}</FormLabel>
          <Controller
            control={control}
            name="isProofMandatory"
            rules={{
              validate: (value) => {
                if (value === undefined) {
                  return translate("errors.required.field");
                }
                return true;
              },
            }}
            defaultValue={false}
            render={({ field }) => (
              <ToggleButtonGroup
                id="isProofMandatory"
                {...field}
                exclusive
                color="primary"
                onChange={(_, newValue) => {
                  setValue("isProofMandatory", newValue, {
                    shouldValidate: true,
                  });

                  return newValue;
                }}
              >
                <ToggleButton value={false} color="warning">
                  {translate("booleanYesNo.false")}
                </ToggleButton>
                <ToggleButton value={true} color="success">
                  {translate("booleanYesNo.true")}
                </ToggleButton>

              </ToggleButtonGroup>
            )}
          />
        </FormControl>

        <FormControl>
          <FormLabel>{translate("pages.definition.errorType.fields.isCommentMandatory")}</FormLabel>
          <Controller
            control={control}
            name="isCommentMandatory"
            rules={{
              validate: (value) => {
                if (value === undefined) {
                  return translate("errors.required.field");
                }
                return true;
              },
            }}
            defaultValue={false}
            render={({ field }) => (
              <ToggleButtonGroup
                id="isCommentMandatory"
                {...field}
                exclusive
                color="primary"
                onChange={(_, newValue) => {
                  setValue("isCommentMandatory", newValue, {
                    shouldValidate: true,
                  });

                  return newValue;
                }}
              >
                <ToggleButton value={false} color="warning">
                  {translate("booleanYesNo.false")}
                </ToggleButton>
                <ToggleButton value={true} color="success">
                  {translate("booleanYesNo.true")}
                </ToggleButton>

              </ToggleButtonGroup>
            )}
          />
        </FormControl>
      </Box>
    </Create>
  );
};
