import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useActiveAuthProvider, useCustom, useCustomMutation, useGetIdentity, useInvalidate, useList, useModal, useNavigation, useNotification, useShow, useTranslate } from "@refinedev/core";
import { ReadBarcodeModal } from "../fabric/roll/modals/readBarcodeModal";
import { useEffect, useRef, useState } from "react";
import { BarcodeFormat, Result } from '@zxing/library';
import { LoadingButton } from "@mui/lab";
import { GetServiceUrl } from "../../helpers/configManager";
import { getLastPartOfUrl } from "../../helpers/stringHelper";
import { useModalList } from "../../hooks";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import { Image, QRCode } from 'antd';
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { IRollResponse } from "../../model/fabric/IRollResponse";
import { isPermitted } from "../../providers/accessControlProvider";
import { useIsPermitted } from "../../hooks/use-is-permitted";
import { useDebounce } from "use-debounce";
import PrintTag, { PrintTagHandle } from "../../components/ticket/PrintTag";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ValueCard } from "../../components/card";
import { IServiceResponse } from "../../model/IServiceResponse";
import { ISystemConfiguration } from "../../model/definition/ISystemConfiguration";
import { IUserResponse } from "../../model/visa/user/IUserResponse";
import { IRoleResponse } from "../../model/visa/role/IRoleResponse";


export const DashboardPage: React.FC = () => {
    const translate = useTranslate();
    const { show, close, visible } = useModal();
    const { open } = useNotification();
    const { show: navigateShow, list: navigateList } = useNavigation();
    var { mutate } = useCustomMutation();

    const [record, setRecord] = useState<IRollResponse>();
    const [currentUserIsManager, setCurrentUserIsManager] = useState(false);

    const hasPermission = useIsPermitted("ROLL");

    const [searchText, setSearchText] = useState('');
    const [debounceTime, setDebounceTime] = useState(3000); // Başlangıç debounce süresi
    const [filterValue] = useDebounce(searchText, debounceTime);
    const [remainingTime, setRemainingTime] = useState(0);
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if (searchText !== filterValue) {
            clearTimeout(timerRef.current);
            setRemainingTime((debounceTime / 1000) - 1);

            const updateRemainingTime = () => {
                setRemainingTime(prevTime => {
                    if (prevTime <= 0) {
                        clearTimeout(timerRef.current);
                        return 0;
                    }
                    return prevTime - 1;
                });
            };

            timerRef.current = setInterval(updateRemainingTime, 1000);

            return () => clearInterval(timerRef.current);
        }
    }, [searchText, filterValue, debounceTime]);


    const { listProps, modalProps } = useModalList({
        resource: "Roll",
        filters: {
            permanent: [
                {
                    operator: "and",
                    value: [
                        {
                            operator: "or",
                            value: [
                                {
                                    field: "IsInApproval",
                                    operator: "eq",
                                    value: true
                                },
                                {
                                    field: "IsVerified",
                                    operator: "eq",
                                    value: true
                                }
                            ]
                        },
                        {
                            operator: "or",
                            value: [
                                {
                                    field: "Code",
                                    operator: "contains",
                                    value: filterValue
                                },
                                {
                                    field: "SellerCode",
                                    operator: "contains",
                                    value: filterValue
                                },
                                {
                                    field: "ExternalParty.Code",
                                    operator: "contains",
                                    value: filterValue
                                },
                                {
                                    field: "ExternalParty.Party.Code",
                                    operator: "contains",
                                    value: filterValue
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        queryOptions: {
            enabled: hasPermission
        }
    });

    const readBarcode = () => {
        show();
    }

    const { setShowId, queryResult } = useShow<IRollResponse>({
        resource: "Roll",
        queryOptions: {
            enabled: false
        }
    });


    const [barcodeReadResult, setBarcodeReadResult] = useState<{ text: string, result: Result }>();

    const navigateRoll = () => {
        //Barkod okunduktan sonra yapılacak işlemler. E128 ve QR için akış olmalı.
        if (barcodeReadResult) {
            const { text, result } = barcodeReadResult;

            if (result.getBarcodeFormat() == BarcodeFormat.QR_CODE) {
                close();
                navigateShow('Roll', removeBasePathFromUrl(text));
            }
            else {
                console.log("Barkod Tipi: " + result.getBarcodeFormat().toString());

                mutate({
                    url: `${GetServiceUrl()}/Roll/getRollIdFromRollCode`,
                    method: 'post',
                    values: {
                        code: getLastPartOfUrl(text)
                    }
                }
                    ,
                    {
                        onError: (error, variables, context) => {
                            close();
                            alert("İlgili barkoda ait top bulunamadı. Okunan değer: " + text)
                        },
                        onSuccess: (data, variables, context) => {
                            navigateShow('Roll', data.data.data!);
                            close();
                        },
                    })
            }
        }
        else
            alert("Barkod verileri alınamadı");
    }

    const onResult = (text: string, result: Result) => {
        //Barkod okunduktan sonra yapılacak işlemler. E128 ve QR için akış olmalı.
        setBarcodeReadResult({
            text,
            result
        });

        if (result.getBarcodeFormat() == BarcodeFormat.QR_CODE) {
            close();
            setShowId(removeBasePathFromUrl(text));
            queryResult.refetch();
            rollInfoModalProps.show();
        }
        else {
            console.log("Barkod Tipi: " + result.getBarcodeFormat().toString());

            mutate({
                url: `${GetServiceUrl()}/Roll/getRollIdFromRollCode`,
                method: 'post',
                values: {
                    code: getLastPartOfUrl(text)
                }
            }
                ,
                {
                    onError: (error, variables, context) => {
                        close();
                        alert("İlgili barkoda ait top bulunamadı. Okunan değer: " + text)
                    },
                    onSuccess: (data, variables, context) => {
                        close();
                        setShowId(data.data.data!);
                        queryResult.refetch();
                        rollInfoModalProps.show();
                    },
                })
        }
    }

    const onError = (err: Error) => {
        open?.({
            message: err.message,
            type: "error",
            description: err.name,
            key: 'error-barcode-reader'
        });
        close();
    }


    const rollInfoModalProps = useModal();


    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "code",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.code"),
                minWidth: 100,
            },
            {
                field: "externalParty.party.code",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.party"),
                minWidth: 100,
                valueGetter: (params: any) => params.row.externalParty.party.code
            },
            {
                field: "externalParty.code",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.externalParty"),
                minWidth: 100,
                valueGetter: (params: any) => params.row.externalParty.code
            },
            {
                field: "fabricType",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.fabricType"),
            },
            {
                field: "rawMaterialCode",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.rawMaterialCode"),
            },
            {
                field: "rawMaterialName",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.rawMaterialName"),
            },
            {
                field: "warehouse",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.warehouse"),
            },
            {
                field: "colorCode",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.colorCode"),
            },
            {
                field: "colorName",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.colorName"),
            },
            {
                field: "dyehouse",
                flex: 1,
                headerName: translate("pages.fabric.roll.fields.dyehouse"),
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                minWidth: 200,
                renderCell: function render({ row }) {
                    setRecord(row);
                    return (
                        <>
                            <Button variant="contained" color="primary" onClick={() => {
                                setRecord(row);
                                handlePrintButtonClick()
                            }}>
                                Etiket Yazdır
                            </Button>
                        </>

                    );
                },
                align: "center",
                headerAlign: "center"
            },
        ],
        []
    );

    const printTagRef = useRef<PrintTagHandle>(null);

    const handlePrintButtonClick = () => {
        if (printTagRef.current) {
            printTagRef.current.downloadPDF();
        }
    };


    const { data, isLoading } = useCustom<IServiceResponse<ISystemConfiguration>>({
        url: `${GetServiceUrl()}/SystemConfiguration/GetCurrentOne`,
        method: 'get',
    });

    const { data: identity } = useGetIdentity<IUserResponse>();


    const { data: approvalList } = useList({
        resource: "Roll",
        meta: {
            controller: "GetWaitingForApprovalsRoll"
        },
        queryOptions: {
            enabled: currentUserIsManager
        }
    });


    useEffect(() => {
        setCurrentUserIsManager(identity?.roles?.find((element: IRoleResponse, index: number, obj: IRoleResponse[]) => element.id == data?.data.data.approvalRoleId) != undefined);
    }, [identity, data])

    return (
        <>
            <Grid container columns={24} spacing={1}
                sx={{
                    marginBottom: 1,
                    display:
                        currentUserIsManager
                            ? "block" : "none"
                }}>
                <Grid item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    xl={6}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <ValueCard
                            title={"Onayda Bekleyenler"}
                            href="/roll/waitingapprovals"
                            children={(approvalList?.total ?? 0)}
                            secondaryChildren={"Adet Onayda Bekleyen Top Mevcut."}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container columns={24} spacing={1} height={currentUserIsManager
                ? "80%" : "100%"}>
                <Grid
                    item
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                >
                    <Card variant="outlined" style={
                        {
                            height: "100%"
                        }
                    }>
                        <CardHeader
                            title={
                                <Grid container columns={2} justifyContent="space-between" alignItems="center">
                                    <Grid item >
                                        <Typography>
                                            Hızlı İşlemler
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <Button style={{
                                            backgroundColor: "cornflowerblue"
                                        }} onClick={() => modalProps.show()} variant="contained" >
                                            <Typography
                                                variant="h6"
                                                fontWeight={700}
                                                color="text.primary"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 3, // Satır sayısını belirleyin
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                QR Kod Yazdır
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        />
                        <CardContent style={{ height: "100%" }}>
                            <Grid container columns={24} spacing={2} height="90%">
                                <Grid
                                    item
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    xl={12}>
                                    <Button style={{
                                        height: "100%",
                                        backgroundColor: "burlywood"
                                    }} fullWidth onClick={() => readBarcode()} variant="contained">
                                        <Typography
                                            variant="h2"
                                            fontWeight={700}
                                            color="text.primary"
                                            sx={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                WebkitLineClamp: 3, // Satır sayısını belirleyin
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {translate("buttons.readBarcode")}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={24}
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    xl={12}>
                                    <Button style={{
                                        height: "100%",
                                        backgroundColor: "cadetblue"
                                    }} fullWidth onClick={() => navigateList('Roll')} variant="contained">
                                        <Typography
                                            variant="h2"
                                            fontWeight={700}
                                            color="text.primary"
                                            sx={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                WebkitLineClamp: 3, // Satır sayısını belirleyin
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            Manuel Giriş
                                        </Typography>

                                    </Button>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container columns={24} spacing={1}>
                                <Grid
                                    item
                                    xs={24}>

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid >

            <ReadBarcodeModal
                show={show}
                close={close}
                visible={visible}
                title="Barcode"
                onResult={onResult}
                onError={onError}
                oneTimeScan={true}
                options={{
                    constraints: {
                        facingMode: 'environment',
                        aspectRatio: { ideal: 1 }
                    }
                }}
            />

            <PrintTag
                ref={printTagRef}
                colorName={record?.colorName ?? ""}
                colorCode={record?.colorCode ?? ""}
                dyeHouse={record?.dyehouse ?? ""}
                externalPartyCode={record?.externalParty.code ?? ""}
                fabricType={record?.fabricType ?? ""}
                partyCode={record?.externalParty.party.code ?? ""}
                rawMaterial={record?.rawMaterialName ?? ""}
                rawMaterialCode={record?.rawMaterialCode ?? ""}
                rollAmount={record?.rollAmount?.toString() ?? ""}
                rollAmountType={record?.rollAmountType ?? ""}
                rollCode={record?.code ?? ""}
                printDate={new Date()}
                createDate={record?.createDate ?? new Date()}
                id={record?.id ?? ""}
                approvementCount={record?.approvementCount ?? 1}
                errorEntryStartTime={record?.errorEntryStartTime}
                errorEntryEndTime={record?.errorEntryEndTime}
                isVerified={record?.isVerified}
            />

            <Dialog
                open={modalProps.visible}
                onClose={modalProps.close}
                PaperProps={{ sx: { minWidth: '100%' } }} container={() => document.getElementById('fullscreen_div')}>

                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <TextField
                            value={searchText}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchText(event.target.value);
                            }}
                            id="searchText"
                            margin="normal"
                            label={"Filtre (" + remainingTime + " saniye)"}
                            name="searchText"
                            type="text"
                            autoComplete="Kumaş Kodu, Harici Parti Kodu, Satıcı Kodu,Parti Kodu"
                            placeholder="Kumaş Kodu, Harici Parti Kodu, Satıcı Kodu,Parti Kodu"
                            size="small"
                            style={{
                                width: "50%",
                                marginLeft: 10
                            }}
                        />

                        <DataGrid
                            {...listProps.dataGridProps}
                            columns={columns}
                            autoHeight
                        />
                    </Stack>

                </DialogContent>
            </Dialog>

            <Dialog open={rollInfoModalProps.visible}
                onClose={rollInfoModalProps.close}
                PaperProps={{ sx: { minWidth: '100%' } }} container={() => document.getElementById('fullscreen_div')}>
                <DialogTitle title="Kumaş Bilgileri" />
                <DialogContent>
                    <Grid container spacing={1} columns={24}>
                        {
                            queryResult.isRefetching ?
                                <Grid item xs={24}>
                                    <CircularProgress />
                                </Grid> :
                                <>
                                    <Grid item xs={24}>
                                        <ValueCard title="Kumaş Parti Kodu" children={queryResult.data?.data.externalParty.party.code} />
                                    </Grid>
                                    <Grid item xs={24}>
                                        <ValueCard title="Kumaş Harici Parti Kodu" children={queryResult.data?.data.externalParty.code} />
                                    </Grid>
                                    <Grid item xs={24}>
                                        <ValueCard title="Kumaş Kodu" children={queryResult.data?.data.code} />
                                    </Grid>
                                    <Grid item xs={24}>
                                        <ValueCard title="Boyahane" children={queryResult.data?.data.dyehouse} />
                                    </Grid>
                                </>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={rollInfoModalProps.close}>
                        Vazgeç
                    </Button>
                    <Button onClick={navigateRoll}>
                        Detaya Git
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

const removeBasePathFromUrl = (text: string) => text.replace("https://kumas.imteks.com.tr/roll/showexternal/", "");